globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"ce76b360ba256536de45fab04905c0ba135057fb"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const disable =
	process.env.SENTRY_DISABLE === 'true' ||
	process.env.NEXT_PUBLIC_SENTRY_DISABLE === 'true';

if (disable) {
	console.info('sentry log is disabled');
} else if (!SENTRY_DSN) {
	console.warn(
		'sentry remote log will not work NEXT_PUBLIC_SENTRY_DSN or SENTRY_DSN env is not set',
	);
} else {
	Sentry.init({
		dsn: SENTRY_DSN,

		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 1,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		replaysOnErrorSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// You can remove this option if you're not planning to use the Sentry Session Replay feature:
		integrations: [
			Sentry.replayIntegration({
				// Additional Replay configuration goes in here, for example:
				maskAllText: true,
				blockAllMedia: true,
			}),
		],
	});
}
